<template>
  <div id="dayreport" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2>Lägg till kommentar</h2>
    </div>
    <div v-if="myDayReport" class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Datum: {{ myDayReport.day_report_date }}</label>
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Kommentar</label>
        <textarea v-model="myDayReport.comments" class="pg-input"></textarea>
      </div>
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyComment">Spara</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'DayReportComments',
  props: {
    dayReportId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      search: '',
      showError: false,
      error: '',
      myDayReport: {
        day_report_date: new Date(),
        user_id: 0,
        user_full_name: '',
        partially_assembled_amount: 0,
        final_assembled_amount: 0,
        final_assembled_electric_locks_amount: 0,
        sold_boxes_amount: 0,
        comments: ''
      }
    };
  },
  computed: {},
  created() {},
  async mounted() {
    if (this.dayReportId > 0) {
      await this.fetchMyDayReport();
    }
  },
  methods: {
    ...mapActions({
      fetchDayReport: 'reports/fetchDayReport',
      updateDayReport: 'reports/updateDayReport'
    }),
    back: function () {
      this.$router.replace({
        name: 'DayReports',
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    async fetchMyDayReport() {
      try {
        this.myDayReport = [];
        let data = {
          day_report_id: this.dayReportId
        };
        this.myDayReport = await this.fetchDayReport(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyComment() {
      let response = null;

      try {
        let data = {
          day_report_id: this.dayReportId,
          day_report_date: format(new Date(this.myDayReport.day_report_date), 'yyyy-MM-dd'),
          user_id: this.myDayReport.user_id,
          user_full_name: this.myDayReport.user_full_name,
          partially_assembled_amount: this.myDayReport.partially_assembled_amount,
          final_assembled_amount: this.myDayReport.final_assembled_amount,
          final_assembled_electric_locks_amount: this.myDayReport.final_assembled_electric_locks_amount,
          sold_boxes_amount: this.myDayReport.sold_boxes_amount,
          comments: this.myDayReport.comments
        };

        response = await this.updateDayReport(data);
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: 'DayReports',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  height: 300px;
}
</style>
